import React, { useState } from "react"
import Img from "gatsby-image"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Formador = ({ data }) => {
  console.log(data)

  const [index, setIndex] = useState(0);

  const [activeTab, setActiveTab] = useState("tab0");


  return (
    <StyledContainer>
      <h4 className={font.nexaBold + " title"}>FORMAÇÃO</h4>
      <h1 className={font.financierMediumItalic + " subtitle"}>Programa</h1>

      <div className="Tabs">
        <ul className="nav">
          {data.dias.map((dia, key) => (
            <li key={'option' + key}
              className={activeTab === "tab" + key ? "active" : ""}
              onClick={() => (setActiveTab("tab" + key), setIndex(0))}
            >
              <h4 className={font.nexaHeavy + " points-title big"}>{dia}</h4>
            </li>
          ))}
        </ul>
        <div className="outlet">
          {data.programa.map((prog, key) => (
            activeTab === ("tab" + key) &&
            <>
              <div className="content">
                <div className="selector">
                  <div className="gray" />
                  {prog.map((dataInner, i) => (
                    <button onClick={() => { setIndex(i) }} className={i !== index ? font.nexaRegular + " text" : font.nexaHeavy + " text selected"} key={"select:" + i}>
                      <div className="dot" />{dataInner.title}</button>
                  ))}
                </div>
                <div className="points">
                  <div className="bg-img">
                    <Img fluid={prog[index].img?.childImageSharp?.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "cover" }} />
                  </div>
                  <h5 className={font.nexaHeavy + " points-title big"}>{prog[index].title}</h5>
                  {prog[index].points?.map((dataInner, i) => (
                    <div className="pointGroup">
                      <p className={font.nexaHeavy + " text points-subtitle"} key={"points:" + i}>{dataInner[0]}</p>
                      {dataInner.length > 1 &&
                        dataInner.map((dataInner2, i) => (
                          i !== 0 &&
                          <p className={font.nexaRegular + " text subtext"} key={"points:" + i}>-{dataInner2}</p>
                        ))
                      }
                    </div>
                  ))}
                </div>
              </div>
            </>

          ))}
        </div>
      </div>
    </StyledContainer >
  )
}

export default Formador

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7vw;
  margin-bottom: 7vw;
  .title{
    color: #717171;
    letter-spacing: 0.38em;
  }
  .subtitle{
    color: #e6e6e6;
    font-size: calc(40px + 40 * (100vw - 769px) / 1791);
    margin: 0.5em 0 0.5em;
    @media only screen and (min-width: 2560px){font-size: 80px;}
    @media only screen and (max-width: 769px){
      font-size: 40px;
      margin: 0.5em 0 0.5em;
    }
  }
  .content{
    display: flex;
    width: 100%;
    height: 60vh;
    text-transform: uppercase;
    .selector{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      color: #717171;
      padding: 5vw;
      padding-left: 10vw;
      position: relative;
      .text{text-align: left;}
      .gray{
        position: absolute;
        top: 0;
        left: 0;
        width: 5vw;
        height: 100%;
        border-radius: 0 60px 60px 0;
        background-color: #EFEFEF;
        @media only screen and (max-width: 768px){
          display: none;
        }
      }
      button{
        display: flex;
        align-items: center;
      }
      .dot{
        max-height: 1vh !important;
        min-height: 1vh !important;
        height: 1vh !important;
        max-width: 1vh !important;
        min-width: 1vh !important;
        width: 1vh !important;
        border-radius: 100%;
        border: 1px solid #717171;
        margin-right: 1em;
      }
      .selected{
        color: #2A94C3;
        font-weight: bold;
        .dot{
          background-color: #2A94C3;
          border: none;
        }
      }
    }
    .points{
      width: 50%;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 5vw;
      border-radius: 60px 0 0 60px;
      position: relative;
      overflow: hidden;
      .bg-img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .pointGroup{
        margin: 0vh 0vh 1vh 0vh;
      }
      .points-title{
        letter-spacing: normal;
        margin-bottom: 2vh;
      }
      .points-subtitle{
        margin: 1em;
      }
      .subtext{
        margin-left: 1em;
        font-style: italic;
        font-size: 0.7em;
        @media only screen and (max-width: 768px){
          font-size: 0.54em;
        }
      }
    }
  }
  
  .text{
    white-space: pre-wrap;
  }

  @media only screen and (max-width: 768px){
    .content{
      flex-direction: column;
      height: unset;
      .selector{
        width: 100%;
        padding: 5vw 4vw 12vw;
        button{
          line-height: 2.5em;
        }
      }
      .points{
        width: 100%;
        border-radius: 0;
        height: 25em;
      }
    }
  }

  
  /* Tab Container */
  .Tabs {
    width: 100%;
    height: auto;
    padding: 2rem 1rem;
    color: #e8f0f2;
    border-radius: 2rem;

    @media (max-width: 769px) {
      padding: 2rem 0;
    }
  }

  /* Tab Navigation */
  ul.nav {
    width: 60%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(23,212,227,0.5);
    border-radius: 2rem;
    background-color: #EFEFEF;
    color: #717171;

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  ul.nav li {
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }

  ul.nav li:nth-child(2) {
    border-radius: 0;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }

  ul.nav li:hover {
    background: rgba(50, 224, 196, 0.15);
  }

  ul.nav li.active {
    background: rgba(23,212,227,0.5);
    color: #fff;
  }
`