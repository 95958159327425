import React from "react"
import Img from "gatsby-image"

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

const Formador = ({ data }) => {

  return (
    <StyledContainer>
      {data.map((item, key) => (
        <div key={'formador'+key}>
          <h4 className={font.nexaRegular + " title"}>{item.title}</h4>
          <div className="media">
            <Img fluid={item.img.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "contain" }} />
          </div>
          <h4 className={font.nexaRegular + " name"}>{item.name}</h4>
          <p className={font.nexaRegular + " text"}>{item.notes}</p>
        </div>
      ))}

    </StyledContainer>
  )
}

export default Formador

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vw;
  margin-bottom: 5vw;
  background-color: #EFEFEF;
  padding: 5vw;
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 5%;
  padding: 5% 10% 5% 10%;
  align-items: start;
  justify-content: center;
  align-content: center;
  text-align: center;
  @media only screen and (max-width: 768px){
    margin: 15vw 0;
  }
  .title{
    color: #17D4E3;
    text-transform: uppercase;
    letter-spacing: 0.38em;
  }
  .media{
    width: 10vw;
    border-radius: 100%;
    overflow: hidden;
    margin: 3vw auto 3vw auto;
    border: 2px solid #f1f1f1;
    @media only screen and (max-width: 768px){
      margin: 5vw 0;
    }
  }
  .name{
    color: #17D4E3;
    margin-bottom: 3em;
  }
  .text{
    text-align: center;
    color: #717171;
    white-space: pre-wrap;
    line-height: 2em;
  }

 
  @media only screen and (max-width: 768px){
    padding: 10vw;
    .media{
      width: 25vw;
    }
  }

  @media (max-width: 990px){
    grid-template-columns: 100%;
    row-gap: 5vh;
    .media{
      margin: 3vw auto 3vw auto;
    }
    .name{
      margin-bottom: 1em;
    }
  }
`