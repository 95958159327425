import React from "react"

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

import FormBox from "./formBox"

const Form = ({data}) => {

  return(
    <StyledContainer id="form">
      <h4 className={font.nexaBold + " title"}>{data.title}</h4>
      <h4 className={font.financierMediumItalic + " subtitle"}>{data.subtitle}</h4>
      <div className="container">
        <div className="info">
          {data.dados.map((data, i)=>(
            <div className="infoBox" key={"infoBox" + i}>
              <h4 className={font.nexaRegular + " infoBox-title"}>{data.title}</h4>
              {i===0 ? 
                <h4 className={font.nexaRegular + " infoBox-subtitle"}>{data.subtitle}</h4>
                :
                <a href={"mailto:" + data.subtitle}><h4 className={font.nexaRegular + " infoBox-subtitle"}>{data.subtitle}</h4></a>

              }
              <p className={font.nexaRegular + " infoBox-text"}>
                {data.text.map((data, i)=>(
                  <span key={"text" + i} style={{fontWeight: i%2!==0 && "bold"}}>
                    {data}
                  </span>
                ))}
              </p>
            </div>
          ))}
        </div>
        <div className="form">
          <FormBox data={data.form} title={data.formacao}/>
        </div>
      </div>
    </StyledContainer>
  )
}

export default Form

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6vw 0;
  @media only screen and (max-width: 768px){
    margin: 15vw 0 0;
  }
  .title{
    color: #717171;
    text-transform: uppercase;
    letter-spacing: 0.38em;
  }
  .subtitle{
    color: #e6e6e6;
    font-size: calc(40px + 40 * (100vw - 769px) / 1791);
    margin: 0.5em 0 1.8em;
    @media only screen and (min-width: 2560px){font-size: 80px;}
    @media only screen and (max-width: 769px){
      margin: 0.5em 0;
      font-size: 40px;
    }
  }
  .container{
    display: grid;
    grid-template-columns: 0.85fr 1fr;
    @media only screen and (max-width: 768px){
      grid-template-columns: 1fr;
    }
    .info{
      width: 100%;
      padding-left: 10vw;
      padding-right: 6vw;
      .infoBox{
        margin: 4vw 0;
        @media only screen and (max-width: 768px){
        margin: 5vw 0 10vw;
      }
        .infoBox-title{
          color: #17D4E3;
          text-transform: uppercase;
          letter-spacing: 0.38em;
        }
        .infoBox-subtitle{
          color: #717171;
          font-weight: bold;
          margin: 1em 0 2em;
        }
        .infoBox-text{
          color: #717171;
          white-space: pre-wrap;
        }
      }
    }
    .form{
      width: 100%;
      height: 100%;
    }
  }
`