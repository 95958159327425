import React from "react"
import { Link } from "gatsby"

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

import arrow from "../../images/svg/areaArrowGrey.svg"

const GlassIntro = ({data}) => {

  return(
    <StyledContainer as={Link} to="#form">
      <h5 className={font.nexaBold + " btn"}>{data}<img src={arrow} alt="seta"/></h5>
    </StyledContainer>
  )
}

export default GlassIntro

const StyledContainer = styled.div`
  position: relative;
  width: fit-content;
  background-color: rgba(23, 212, 227, 0.5);
  color: #717171;
  border-radius: 13px;
  margin: 6vw auto 8vw;
  .btn{
    display: flex;
    align-items: center;
    padding: 1em 4em;
    letter-spacing: normal;
    img{
      height: 0.8em;
      margin-left: 2em;
    }
  }
`