import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Intro from "../components/intro"
import GlassIntro from "../components/formacao/glassintro"
import Apresentacao from "../components/apresentacao"
import ApresentacaoMobile from "../components/apresentacaoMobile"
import Formbtn from "../components/formacao/formbtn"
import Programa from "../components/programa"
import Media from "../components/media"
import Formador from "../components/formacao/formador"
import Form from "../components/formacao/form"
import Footer from "../components/footer"

import HeaderMobile from "../components/headerMobile"
import FooterMobile from "../components/footerMobile"

const FormacaoPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Formação" />
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile header={data.globalJson.header} />
          <Intro data={data.formacaoJson.introM} blackBlue={true} />
          <GlassIntro data={data.formacaoJson.glassintroMobile} />
          <div style={{ width: "100%", height: "1px", marginTop: "-100px" }} />
          <ApresentacaoMobile data={data.formacaoJson.apresentacao} />
          <Programa data={data.formacaoJson.programas} />
          <Media data={data.formacaoJson.media} />
          <Formador data={data.formacaoJson.formador} />
          <Form data={data.formacaoJson.form} />
          <FooterMobile data={data.globalJson.footer} />
        </>
      ) : (
        <>
          <Header header={data.globalJson.header} />
          <Intro data={data.formacaoJson.intro} blackBlue={true} />
          <GlassIntro data={data.formacaoJson.glassintro} />
          <Apresentacao data={data.formacaoJson.apresentacao} />
          <Formbtn data={data.formacaoJson.formbtn} />
          <Programa data={data.formacaoJson.programas} />
          <Media data={data.formacaoJson.media} />
          <Formador data={data.formacaoJson.formador} />
          <Form data={data.formacaoJson.form} title={data.formacaoJson.form.formacao}/>
          <Footer data={data.globalJson.footer} />
        </>)
      }
    </Layout>
  )
}

export default FormacaoPage

export const Json = graphql`
  query formacao {
    globalJson{
      header{
        menu{
          title
          link
        }
        menuselect{
          publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      footer{
        logo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        calendarImg {
          publicURL
        }
        calendarTxt
        calendarLink
        schedule {
          days
          hours
        }
        phone {
          ind
          number
          txt
        }
        address
        links {
          title
          link
        }
        map {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bottomTxt
        social
        {
          face
          insta
          tube
        }
      }
      team{
        title
        name
        position
        omd
        specialty
        courses
        photo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 800) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        icon{
          childImageSharp {
            fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bio
      }
    }
    formacaoJson {
      intro{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
      }
      introM{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
      }
      glassintro{
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        date
      }
      glassintroMobile{
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        date
      }
      apresentacao{
        subtitle
        title
        text
      }
      formbtn
      programas{
        dias
        programa{
          title
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          points
        }
      }
      
      media{
        title
        subtitle
        text
        media{
          childImageSharp {
            fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      formador{
        title
        img{
          childImageSharp {
            fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        name
        notes
      }
      form{
        title
        subtitle
        formacao
        dados{
          title
          subtitle
          text
        }
        form{
          title
          contacto{
            title
            name
            categoria{
              title
              options
            }
            email
            phone
          }
          fatura{
            title
            name
            nif
            morada
            comprovativo
            btn
          }
          termos
          btn
        }
      }
    }
  }
`