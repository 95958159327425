import React from "react"
import Img from "gatsby-image"

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

import Glass from "../glassCard"

const GlassIntro = ({data}) => {

  return(
    <StyledContainer>
      <Glass>
        <div className="date">
          <Glass>
            <h2 className={font.nexaBold + " text"}><span>{data.date[0]}</span><span>{data.date[1]}</span></h2>
            <p className={font.nexaBold + " text"}><span>{data.date[2]}</span><span>{data.date[3]}</span></p>
          </Glass>
        </div>
        <div className="img-container">
          <Img fluid={data.img.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain"}}/>
        </div>
      </Glass>
    </StyledContainer>
  )
}

export default GlassIntro

const StyledContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 70%;
  margin-left: 15%;
  margin-top: -55vh;
  height: 40vw;
  min-height: 60vh;
  .date{
    position: absolute;
    top: 0;
    left: 7vw;
    transform: translate(-50%, -50%);
    .text{
      display: flex;
      justify-content: center;
    }
  }
  .img-container{
    padding: 6vw 5vw;
  }
  @media (max-width: 990px){
    width: 80%;
    margin-left: 10%;
    margin-top: 0vh;
  }
`